import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Paper,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  styled,
  TextField,
  CardContent,
  Dialog, DialogTitle, DialogContent, DialogActions,
  // Customizable Area End
  IconButton,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { FileImage } from "./assets";
import CloseIcon from '@mui/icons-material/Close'; 


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const DatePickerComp = styled(Box)(({ theme }) => ({
  ".react-datepicker": {
  width: "100%", 
  maxWidth: "100%",
},
".react-datepicker__month-container": {
  width: "100%", 
  maxWidth: "100%",
},
  '.react-datepicker-wrapper': {
    width: "100%",
  },
  ".react-datepicker__input-container": {
    width: "100%",
  },
  ".react-datepicker__day-names": {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)", 
    width: "100%",
    textAlign: "center",
    margin:"0.4rem",
  },
  ".react-datepicker__week": {
    display: "grid", 
    gridTemplateColumns: "repeat(7, 1fr)",
    width: "100%",
    textAlign: "center",
    margin:"0",
    padding:"0",
  },
  ".react-datepicker__input-container input": {
    width: "100%",
    padding: "15px",
    borderRadius: "5px",
    outline: "none",
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  ".react-datepicker__header": {
    textAlign: "center",
    backgroundColor: "#fff",
    borderBottom: "none",
    padding: "17px 0 0 0",
    position: "relative",
  },
  ".react-datepicker__day": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: "2.5em",
    width: "100%",
    maxWidth:"100%",
    margin: "0", 
  },
  ".react-datepicker__day--in-range": {
    backgroundColor: "#FFF4DB",
    color: "#000000",
    borderRadius: "0", 
    border: "none",
  },
  ".react-datepicker__day--range-start": {
    backgroundColor: "#FFF4DB", 
    color: "#000000",
    borderRadius: "50% 0 0 50%",
  },
  ".react-datepicker__day--range-end": {
    backgroundColor: "#FFF4DB", 
    color: "#000000",
    borderRadius: "0 50% 50% 0",
  },
  ".react-datepicker__day--selected": {
    backgroundColor: "#FFF4DB",
    color: "#000000",
    borderRadius: "50%", 
    border: "none",
  },
}));

// Customizable Area End

import ImportExportDataController, {
  Props,
  configJSON,
} from "./ImportExportDataController.web";

export default class ImportExportData extends ImportExportDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 services = [
    "Completed Clean-up or Request Clean-up",
    "Harm Reduction Supplies Distribution",
    "Referrals and Warm Hand Offs",
    "Performed and Facilitated Care",
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={""}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
          <Box sx={styles.container}>
          <Box onClick={this.handleGoBack} style={styles.goback} data-test-id="goback_button">
              <ArrowBackIosIcon style={styles.arrowIcon} />
              <Typography style={styles.goBackText}>Go Back</Typography>
            </Box>
        <Typography variant="h6" sx={{fontSize:"24px",lineHeight:"32px",fontWeight:700,fontFamily:"Inter",marginBottom:"15px"}}>Data Extracts</Typography>
       <Paper sx={{padding:"20px",background:"#FFFFFF"}}>
       <Typography sx={styles.styledText}>Services:</Typography>
       <Card sx={styles.card}>
        <CardContent sx={{ padding: 0 }}>
          {this.services.map((service, index) => (
            <FormControlLabel
              key={index}
              control={<Checkbox 
                sx={styles.checkbox} 
                data-testid="checkboxId"
                checked={this.state.selectedService === service}
                onChange={() => this.handleCheckboxChange(service)} />}
              label={service}
              sx={{
                margin: 0,
                width: '100%',
                borderBottom: index !== this.services.length - 1 ? '1px solid #E0E0E0' : 'none',
                padding: '5px 0',
                '& .MuiFormControlLabel-label': styles.label,
              }}
            />
          ))}
        </CardContent>
      </Card>
       <Typography sx={styles.styledText}>Date Range:</Typography>
       <Box sx={styles.dateRangeContainer}>
            <Box sx={styles.datePickerRow}>
              <Typography sx={styles.dateLabel}>From:</Typography>
              <DatePickerComp>
                <TextField
                  data-testid="start-date-input"
                  variant="outlined"
                  size="small"
                  value={this.state.startDate ? this.state.startDate.toLocaleDateString() : ""}
                  onClick={() => this.handleActivePicker("start")}
                  sx={styles.inputRoot}
                />
              </DatePickerComp>
            </Box>

            <Box sx={styles.datePickerRow}>
            <Typography sx={styles.dateLabel}>To:</Typography>
            <DatePickerComp>
              <TextField
              data-testid="end-date-input"
                variant="outlined"
                size="small"
                value={this.state.endDate ? this.state.endDate.toLocaleDateString() : ""}
                onClick={() => this.handleActivePicker("end")}
                sx={styles.inputRoot}
              />
            </DatePickerComp>
            </Box>

          <DatePickerComp>
            {this.state.activePicker === "start" && (
                <DatePickerComp>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                inline
                maxDate={this.state.endDate}
              />
              </DatePickerComp>
            )}

            {this.state.activePicker === "end" && (
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                inline
                minDate={this.state.startDate}
              />
            )}

            {this.state.activePicker === "dateRange" && (
              <DatePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                inline
              />
            )}
          </DatePickerComp>
        </Box>
        </Paper>
        <Divider sx={{color:"#D9D9D9",margin:"8% 0", borderTop: "2px solid #D9D9D9", }}></Divider>
        <Box sx={styles.buttonContainer}>
        <Button sx={styles.button} variant="contained"  onClick={this.toggleModal}>
          Export Data Extract
        </Button>
      </Box>
      <Dialog
        open={this.state.openModal}
        onClose={this.toggleModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        fullWidth 
      >
        <Box sx={styles.dialogTitle}>
          <DialogTitle id="modal-title" sx={styles.dialogTitle}>
            Cancel Extract
          </DialogTitle>
          <CloseIcon 
            onClick={this.toggleModal} 
            sx={styles.closeIcon}
          />
        </Box>

        <DialogContent sx={styles.dialogContent}>
          <img src={FileImage.default} alt="file" />
          <Typography id="modal-description">
            Export Your Data Extract
          </Typography>
        </DialogContent>

        <DialogActions sx={styles.dialogActions}>
          <Button
            onClick={this.toggleModal}
            variant="outlined"
            sx={styles.cancelButton}
             data-test-id="cancel_button"
          >
            Cancel
          </Button>
          <Button
            onClick={this.exportData}
            variant="contained"
            sx={styles.exportButton}
            data-test-id="export_button"
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
      </Container>
    </ThemeProvider>
      </StyledEngineProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  buttonStyle: {
    border: "none",
    backgroundColor: "#F5C502",
    height:"44px",
    minWidth:"330px",
    width:"100%",
  },
};
const styles = {
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop:"20px",
    alignItems:"center",
    backgroundColor: "#fff",
  },
  styledText:{
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    fontFamily:"Inter",
    margin:"10px 0",
    lineHeight:"24px"
  },
  goback:{
  height: "22px",
  width: "80px",
  display:"flex",
  gap:"5px",
  alignItems:"center",
  marginBottom:"10px",
  },
  arrowIcon:{
    fontSize:"14px",
    fontWeight:400,
    color:"#2D2A24"
  },
   goBackText: {
    color:"#1D4ED8",
    fontSize:"14px",
    fontWeight:400,
  },
  formControl: {
    marginBottom: 16,
  },
  datePickerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  calendar: {
    marginTop: 16,
  },
  button: {
    backgroundColor: "#F5C502", 
    textTransform:'none',
    height:"44px",
    width:"100%",
    color:"#2D2A24",
    fontSize:"16px", 
    fontWeight:700,
    fontFamily:"Inter",
    lineHeight:"24px",
    marginBottom:"10px",
      
    '&:hover': {
      backgroundColor: "#f9a825", 
    },
  },
  buttonContainer: {
    MarginTop:"20%",
    marginBottom:"10%",
  },
  subCheckBox: {
    color: "#64748B",
    borderRadius: "6px",
    width:"16px",
    height:"16px",
    '&.Mui-checked': {
      color: "#CF9500",
    },
    '& .MuiSvgIcon-root': {
      fontSize: "16px",
    },
  },
  dateRangeContainer: {
    marginTop: "2px",
    display: "flex",
    gap: "5px",
    flexDirection: "column"
  },
  datePickerRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center"
  },
  dateLabel: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400
  },
  inputRoot: {
    maxWidth:"200px",
    borderRadius:"10%",
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#CBD5E1', 
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFF787', 
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFF787', 
      },
    },
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    paddingBottom:"0px",
    border:"1px solid #E2E8F0",
    '& .MuiCardContent-root:last-child ':{
      paddingBottom:"0px"
    }
  },
  checkbox: {
    '&.Mui-checked': {
      color: '#D1A649', 
    },
  },
  label: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#2D2A24',
  },

  dialogTitle: {
    fontSize: '24px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',           
  },
  closeIcon: {
    cursor: 'pointer',  
    position:'absolute', 
    right:"10px",
    top:"10px",      
    fontSize: '20px',        
  },
  dialogContent: {
    fontSize: '16px',
    fontFamily: 'Inter',
    display:"flex",
    alignItems:"center",
    flexDirection:"column", 
    gap:"20px",
    padding: 0,
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding:"10%"
  },
  cancelButton: {
    border: '1px solid red',
    fontFamily: 'Inter',
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 600,
  },
  exportButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    background:'#F5C502',
    color:"#2D2A24",
    fontWeight: 600,
  }
};
// Customizable Area End
