import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface Service{
  [x: string]: unknown;
  name: any;
  checked: boolean | undefined;
  id: string,
  type: string,
  checkedIds:boolean,
  attributes: {
      sub_services: any;
      name: string,
      service_offered_name: string,
      created_at: string,
      updated_at: string,
      checked: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface Item {
  id: number;
  name: string;
  input_type:string;
  count:number | string
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedService: string;
  items:Item[],
  allServices:Service[],
  token:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class HealthcareAccessFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllServicesCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedService: "",
      items:[
        {
            "id": 8,
            "name": "Sniffling Straws",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 6,
            "name": "Cookers",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 5,
            "name": "Orange Tops",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 3,
            "name": "Smoking Pipes",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 4,
            "name": "Fit Kits",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 2,
            "name": "Cotton Balls",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 1,
            "name": "Syringes",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 10,
            "name": "Narcan Training:",
            "input_type": "text_field",
            "count":0
        },
        {
            "id": 7,
            "name": "Elastic Tie (Tourniquet)",
            "input_type": "integer_field",
            "count":0
        },
        {
            "id": 9,
            "name": "Gauze 2",
            "input_type": "integer_field",
            "count":0
        }
    ],
    allServices:[],
    token:""
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

 

  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
      if (apiRequestCallId ===  this.apiGetAllServicesCallId) {
      
        
          
          this.setState({allServices:responseJson.data,},()=>this.addCheckedFieldToSubServices())
        
       
    
        
    }}
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
 this.getToken();
  }
  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>this.getAllServices())
  };
  getAllServices = () => {
   
    const header = {
      token:this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllServicesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllServices
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
 
  
  addCheckedFieldToSubServices() {
   
    this.setState(
    (prevState) => ({
      allServices: prevState.allServices.map((service) => ({
        ...service,
        attributes: {
          ...service.attributes,
          sub_services: service.attributes.sub_services.map(
            (subService: {
              name: any; id: any; checked?: boolean; destroy?: boolean 
}) => {
           
              return {
                ...subService,
                checked: false,
                destroy: false, 
               
               
              };
            }
          ),
        },
      })),
    })
    
  );

  }
  handleToggle = (selectedservice: Service, selectedsubService: Service) => {
    const toggleSubservice = (
      subservice: { checked: boolean; id: string | null; destroy?: boolean },
      selectedsubService: Service
    ) => {
     
      if (subservice.id && subservice.id === selectedsubService.id) {
        return {
          ...subservice,
          checked: !subservice.checked,
          destroy: subservice.checked ? true : false,
        };
      }
  
      if (!subservice.id && subservice === selectedsubService) {
        return {
          ...subservice,
          checked: !subservice.checked,
        };
      }
  
    
      return subservice;
    };
  
    const updateService = (service: Service, selectedservice: Service) => {
      if (selectedservice.id !== service.id) return service;
  
      return {
        ...service,
        attributes: {
          ...service.attributes,
          sub_services: service.attributes.sub_services.map((subservice: { checked: boolean; id: string | null; destroy?: boolean; }) =>
            toggleSubservice(subservice, selectedsubService)
          ),
        },
      };
    };
  
    this.setState((prevState) => ({
      allServices: prevState.allServices.map((service) =>
        updateService(service, selectedservice)
      ),
    }));
  };
  // Customizable Area End
}
